@font-face {
  font-family: "ibm";
  src: local("ibm"),
    url("./fonts/filmify-ibm-plex-mono-semi-bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "josefin";
  src: local("josefin"),
    url("./fonts/filmify-josefin-slab-regular.ttf") format("truetype");
  font-weight: bold;
}
body {
  margin: 0% 20%;
  background: linear-gradient(to bottom, rgb(220, 220, 220) 30%, white 70%);
}

h1 {
  color: rgb(56, 56, 56);
  font-family: ibm;
}
h2 {
  color: rgb(56, 56, 56);
  font-family: ibm;
}
h3 {
  color: rgb(56, 56, 56);
  font-family: ibm;
}
h4 {
  color: rgb(56, 56, 56);
  font-weight: bold;
  font-family: josefin;
}
p {
  color: rgb(56, 56, 56);
  font-size: large;
  font-family: josefin;
}
